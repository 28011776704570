import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import * as headerStyles from '../styles/common.module.css';

const TermsPage = () => (
  <Layout>
    <SEO
      title="Page Three"
      description="Page three description"
    />

    <header className={headerStyles.he}>

      <nav>
        <ul className={headerStyles.navList} >
          <li>
            <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/terms-of-service"><u>Terms</u></Link>
          </li>

          <li>
            <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/privacy">Privacy Policy</Link>
          </li>

          <li>
            <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/access">Accessibility</Link>
          </li>
        </ul>
      </nav>
    </header>

    <p>Will add some content here soon...</p>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default TermsPage;
